//引入axios
import axios from 'axios'
import { Toast } from "vant";
import { getAuthorize } from '../assets/js/authorization?v1'

console.log(process.env.NODE_ENV, '???????????333333333333333');

let http = axios.create({
    // baseURL:'http://crmapi.zsk.dev.tax861.com.cn'//后端服务器地址
    baseURL:'https://crmapi.dstaxweb.com'//后端服务器地址
})
//请求拦截
http.interceptors.request.use((req) => {
    req.headers.authorization = localStorage.getItem('auth') ? localStorage.getItem('auth') : ''
    return req
})
//响应拦截
http.interceptors.response.use(res => {
    if (res.data.code === 200000001) {
        Toast('未登录')
        localStorage.clear()
        getAuthorize()
    }
    else if (res.data.code !== 0) {
        Toast({
            duration: 2000, // 持续展示 toast
            message: res.data.message,
        });
    }


    return res.data
})


export default http
