window.onload = function () {
  /*750代表设计师给的设计稿的宽度，你的设计稿是多少，就写多少;100代表换算比例，这里写100是为了以后好算,比如，你测量的一个宽度是100px,就可以写为1rem,以及1px=0.01rem等等*/
  var oWidth = document.body.clientWidth || document.documentElement.clientWidth;
  if (oWidth > 750) {
    getRem(1920, 100);
  } else {
    getRem(750, 100);
  }
};
window.onresize = function () {
  var oWidth = document.body.clientWidth || document.documentElement.clientWidth;
  if (oWidth > 750) {
    getRem(1920, 100);
  } else {
    getRem(750, 100);
  }
};
function getRem(pwidth, prem) {
  var html = document.getElementsByTagName("html")[0];
  var oWidth = document.body.clientWidth || document.documentElement.clientWidth;
  if (pwidth > 750) {
    html.style.fontSize = oWidth / pwidth * prem + "px";
  } else {
    html.style.fontSize = oWidth / pwidth * prem + "px";
  }



}