import vue from '../../main' //引入vue 实例
import { crmRequest } from '../../until/index'
import { Toast } from 'vant';

export function getUrlParms(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null)
        return unescape(r[2]);
    return null;
}
// 传前端地址 获取code   去换取token
export function getAuthorize() {
    var params = { redirect_url: window.location.href }  //去掉问号之后的
    crmRequest.getauthurl(params).then(res => {
        if (res.code == 0) {
            window.sessionStorage.setItem("isAuthBase", 'true');
            // // 跳转授权页面
            window.location.href = res.data.info.url;
        }
    }).
        catch(err => {
            console.log(err);
        });
}

//  传 code 去换取 token
export function silentAuthorization() {
    if (!sessionStorage.getItem("isAuthBase")) {
        getAuthorize();
        return;
    }
    var code = getUrlParms('code');
    var params = { code: code }
    crmRequest.getuserinfo(params).then(res => {
        if (res.code == 0) {
            let auth = res.data.info.authorization;
            let user_id = res.data.info.user_id;
            let user_name = res.data.info.user_name;
            let id = res.data.info.id;
            window.localStorage.setItem("auth", auth);
            window.localStorage.setItem("user_id", user_id);
            window.localStorage.setItem("user_name", user_name);  //
            window.localStorage.setItem("id", id);
            sessionStorage.removeItem('isAuthBase');
            vue.$store.commit('amendAuthStatus', true)
            deployConfig()
        }
        else {
            getAuthorize()
        }
    }).
        catch(err => {
            console.log(err);

        })

}
//1. 配置config
export function deployConfig() {
    return new Promise((resolve, reject) => {
        let params = {
            url: window.location.href
        }
        // 配置config接口
        crmRequest.getconfig(params).then(res => {
            if (res.code == 0) {
                let data = {
                    ...res.data.info, beta: true
                }
                wx.config(data)
            } else {
            }
            wx.ready(function (res) {
                deployConfigAgent()
                resolve(true)
            });
        })
    })
}
// 2.配置 ConfigAgent
export function deployConfigAgent() {
    return new Promise((resolve, reject) => {
        crmRequest.getconfigagent({
            url: window.location.href
        })
            .then(res => {
                if (res.code == 0) {
                    let config = res.data.info
                    wx.agentConfig(config);
                    let success = function (res) {
                        JudgeEnvironment()
                    }
                    let fail = function (res) {
                        if (res.errMsg.indexOf("function not exist") > -1) {
                            alert("版本过低请升级");
                        }
                    }
                    config.success = success
                    config.fail = fail
                    wx.ready(function () {
                        resolve(true)
                    });
                    wx.error(function (res) {
                    });
                } else {
                    alert('配置失败')
                    // 配置ConfigAgent失败

                }
            })


    })

}
// 检测入网环境
function JudgeEnvironment() {
    wx.invoke('getContext', {
    }, function (res) {
        if (res.err_msg == "getContext:ok") {
            let entry = res.entry; //返回进入H5页面的入口类型，目前有normal、contact_profile、single_chat_tools、group_chat_tools、chat_attachment
            if (entry == 'group_chat_tools' || entry == 'single_chat_tools') {
                // 获取信息接口      
                wx.invoke('getCurExternalContact', {
                }, function (res) {
                    if (res.err_msg == "getCurExternalContact:ok") {
                        let userId = res.userId; //返回当前外部联系人userId
                        sessionStorage.setItem('external_userid', userId)
                        setTimeout(() => {
                            vue.$bus.$emit('getbasicinfo')
                            vue.$router.push(window.location.pathname)
                            vue.$store.commit('closeLoading')
                        })
                    } else {
                        //从单聊会话的工具栏进入 或者 从群聊会话的工具栏进入
                        Toast.fail({
                            overlay: true,
                            duration: 0,
                            message: '外部群聊没有访问权限',
                            forbidClick: true,
                        });
                    }
                });
            } else {
                // 通过应用推送的提醒进入
                vue.$store.commit('revampEntryDetailWay', 1)
                return false
            }
        } else {
            // 没有获取getContext权限 （调用该getContext接口可以判断用户是从哪个入口打开页面，从而决定是否可以调用客户联系相关的接口）
        }
    });
}

