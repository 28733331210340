import Vue from 'vue'
import Vuex from 'vuex'
import { Toast } from 'vant';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: null, //弹出层  
    entryDetailWay:0,  // 0 ：通过待办点击进入详情页面， 1：通过应用推送消息进入详情
    authStatus:localStorage.getItem('auth')?true:false,//是否完成静默授权
  },
  getters: {
    // 读取entryDetailWay 
    readEntryDetailWay(state){
      return state.entryDetailWay
    },
    // 读取auth状态
    readAuthStatus(state){
      return state.authStatus
    },
  },
  mutations: {
    // 初始化弹框
    initializeLoading(state) {
      state.loading = Toast.loading({
        overlay: true,
        duration: 0,
        message: '加载中...',
        forbidClick: true,
      });
    },
    // 关闭弹出层
    closeLoading(state) {
      state.loading.clear()
    },
    // 修改进入详情的方式
    revampEntryDetailWay(state,payload){
      state.entryDetailWay=payload
    },
    // 修改auth 获取状态
    amendAuthStatus(state,plyload){
       state.authStatus=plyload
    }
  },
  actions: {

  },
  modules: {
  }
})
