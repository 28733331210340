//引入http
import http from './axios'
// let ip1 = 'http://crmapi.xm.dev.wenxianghao.cn' //淼哥接口域名
// let ip2 = 'http://crmapi.zsk.dev.tax861.com.cn' //思凯接口域名
// let ip3 = 'http://crmapi.lmj.dev.wenxianghao.cn' //明杰接口域名

// 检测token是否过期
function detectiontoken(params) {
    return http.get('/checktoken', { params })
}
//   获取登录授权地址
function getauthurl(params) {
    return http.get('/login/authurl', { params })
}
//   根据授权code获取用户信息
function getuserinfo(data) {
    return http.post('/login/getuserinfo', data)
}
//获取jssdk配置
function getconfig(params) {
    return http.get('/config/getconfig', { params })
}
//获取jssdk配置
function getconfigagent(params) {
    return http.get('/config/getconfigagent', { params })
}
// 查询客户基本信息
function getcontacts(data) {
    return http.post('/crm/contacts', data)
}
// 更新客户基本信息
function updatecontacts(data) {
    return http.post('/crm/contacts/update', data)
}
// 跟踪信息-查看跟进记录接口
function getrecordsinfo(params) {
    return http.get('/crm/records/getinfo', { params })
}
// 跟踪信息-添加跟进记录接口
function recordsaddinfo(data) {
    return http.post('/crm/records/addinfo', data)
}
// 客户管理列表-OA客户查询
function getcustomerinfo(params) {
    return http.get('/customer/getinfo', { params })
}
// 获取省市区接口
function getregionlist() {
    return http.get('/region/regionlist')
}
// 企业信息-查询联系人企业信息
function getenterpriseinfo(params) {
    return http.get('/customer/getenterpriseinfo', { params })
}
// 企业信息-添加修改企业信息
function addenterpriseinfo(data) {
    return http.post('/customer/addenterpriseinfo', data)
}
// 客户管理-根据外部联系人获取关联的公司名称
function getcompany(params) {
    return http.get('/customer/getcompany', { params })
}
// 转报备 转报备成功客户归属人就成为自己
function reporting(data) {
    return http.post('/customer/reserveturnreporting', data)
}
// customer/offer/addQuote 添加报价
function addQuote(data) {
    return http.post('/customer/offer/addQuote', data)
}
// 报价类型
function businesstypelist(params) {
    return http.get('/customer/offer/businesstypelist', { params })
}

// 获取待办列表
function calendarlist(params) {
    return http.get('/crm/calendar/get', { params })
}
//更新待办列表状态
function updatecalendar(data) {
    return http.post('/crm/calendar/update', data)
}
// 待办详情
function getdetail(params) {
    return http.get('/crm/calendar/getDetail', { params })
}
// 跟踪记录@人列表搜索
function getAtPersonInfo(params) {
    return http.get('/crm/records/getAtPersonInfo', { params })
}
// 人员基础--职务列表
function getPostlist(params) {
    return http.get('/crm/contacts/postlist', { params })
}

export var crmRequest = {
    getauthurl: getauthurl,
    getuserinfo: getuserinfo,
    getconfig: getconfig,
    getconfigagent: getconfigagent,
    getcontacts: getcontacts,
    updatecontacts: updatecontacts,
    getcustomerinfo: getcustomerinfo,
    getrecordsinfo: getrecordsinfo,
    recordsaddinfo: recordsaddinfo,
    getregionlist: getregionlist,
    getenterpriseinfo: getenterpriseinfo,
    addenterpriseinfo: addenterpriseinfo,
    getcompany: getcompany,
    reporting: reporting,
    addQuote: addQuote,
    businesstypelist: businesstypelist,
    detectiontoken: detectiontoken,
    calendarlist: calendarlist,
    updatecalendar: updatecalendar,
    getdetail: getdetail,
    getAtPersonInfo: getAtPersonInfo,
    getPostlist: getPostlist

}

// 获取回访待办/已办列表 (回访任务 已完成/未完成)
function getTaskList(params) {
    return http.get('/crm/visit/gettasklist', { params })
}
// 获取客户拜访码
function getvisitqrcode(params) {
    return http.get('/crm/visit/getvisitqrcode', { params })
}
// 回访首页弹窗提示
function gettips(params) {
    return http.get('/crm/visit/gettips', { params })
}
// 查看客户信息(某条客户信息)
function getvisitinfo(params) {
    return http.get('/crm/visit/getvisitinfo', { params })
}
// 今年已合作业务列表
function businesslist(params) {
    return http.get('/crm/visit/getcollaboratedbusinesstype', { params })
}
// 外出打卡记录列表
function clockinhistory(params) {
    return http.get('/crm/visit/getfollowupattendancerecords', { params })
}
// 修改客户信息

function updatevisitinfo(data) {
    return http.post('/crm/visit/visitbasicinfoupdate', data)
}
// 上传图片
export function uploadimg(data) {
    return http.post('/common/image/upload', data)
}
// 拜访内容提交
function visitcontentsubmit(data) {
    return http.post('/crm/visit/visitmatter', data)
}
// 校验拜访 前两项是否完成
function checkisedit(data) {
    return http.post('/crm/visit/checkvisitmatteredit', data)
}


export var crmVisitRequest = {
    getTaskList: getTaskList,
    getvisitqrcode: getvisitqrcode,
    gettips: gettips,
    getvisitinfo: getvisitinfo,
    businesslist: businesslist,
    clockinhistory: clockinhistory,
    updatevisitinfo: updatevisitinfo,
    uploadimg: uploadimg,
    visitcontentsubmit: visitcontentsubmit,
    checkisedit: checkisedit
}

//  企业微信催款工具
class Report {
    //预览话术 
    previewSpeech(params) {
        return http.get('/crm/report/get_preview_script', { params })
    }
    // 给客户微信发送消息
    sendWeworkMessage(params) {
        return http.get('/crm/report/send_wework_message', { params })
    }
    // 获取客户签订的合同列表
    getContactsList(params) {
        return http.get('/crm/collectiontool/get_contract_signing_list', { params })
    }
    // 获取定版报告列表
    getReportList(params) {
        return http.get('/crm/report/get_final_report_list', { params })
    }

    // 获取承诺函
    getCommitmentLetter(params) {
        return http.get('/crm/report/get_commitment_letter_info', { params })
    }
    // 发送承诺函
    sendCommitmentLetter(data) {
        return http.post('/crm/report/send_commitment_letter', data)
    }

}

export let reportRequest = new Report()

//获取文件列表
function filelist(params) {
    return http.get('/crm/file/getcataloguelist', { params })
}

//获取最新material_id
function sendfile(params) {
    return http.get('/crm/file/getsendfileinfo', { params })
}

//检索文件列表
function searchfile(params) {
    return http.get('/crm/file/getretrievallist', { params })
}
// 企业微信文件管理
export var fileManageRequest = {
    filelist,
    sendfile,
    searchfile
}


// 企业微信继续教育
class continuingEducation {

    // 获取职称列表
    titlelist(params) {
        return http.get('/customer/continuingeducation/titlelist',{ params })
    }

    // 获取继续教育基础信息
    getInfo(params) {
        return http.get('/customer/continuingeducation/getcontinuingeducationinfo',{ params })
    }

    // 提交继续教育信息
    submitContinuingEducation(data) {
        return http.post('/customer/continuingeducation/add', data)
    }
}


export let continuingEducationRequest = new continuingEducation()




