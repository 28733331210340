<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>

<script>
  import {
    crmRequest
  } from './until/index.js'
  import {
    Toast
  } from 'vant';
  import {
    mapMutations
  } from 'vuex'
  import {
    getUrlParms,
    getAuthorize,
    silentAuthorization,
    deployConfig
  } from './assets/js/authorization.js?v4'
  export default {
    data() {
      return {};
    },
    mounted() {
      // 加载层初始化

      this.initializeLoading()
      var auth = localStorage.getItem("auth");
      if (auth) {
        deployConfig()
      } else {
        if (sessionStorage.getItem("isAuthBase")) {
          var code = getUrlParms("code");
          if (code) {
            silentAuthorization(code);
          }
        } else {
          getAuthorize();
        }
      }
      
    },
    methods: {
      ...mapMutations(['initializeLoading', 'closeLoading'])
    }
  };
</script>
<style scoped>
  #app {
    width: 100%;
    height: 100vh !important;
    background-color: #EBEDF0;
  }

  #app::-webkit-scrollbar {
    display: none;
  }
  body {
    overflow-x: hidden;
    overflow-y: auto;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .loading {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, .5);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
  }
</style>