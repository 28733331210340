import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/customerInfo',
  },
  {
    name: 'customerInfo',
    path: '/customerInfo',
    component: () => import('../views/customerInfo.vue'),
    meta: {
      keepAlive: true   //需要缓存
    },
  }
  ,
  {
    path: '/backlogdeatil',
    name: 'backlogdeatil',
    component: () => import('../views/backlogdetail.vue'),
  },
  {
    path: '/visit',//客户回访
    component: () => import('@/customer-visit/index.vue'),
  },
  {
    path: '/report',//客户回访
    component: () => import('@/views/report/index.vue'),
  },

  // 素材
  {
    path: '/material',
    name: 'material',
    component: () => import('../views/material/material.vue'),
  },
  {
    path: '/materialdetail',
    name: 'materialdetail',
    component: () => import('../views/material/materialdetail.vue'),
  },
]

const router = new VueRouter({
  routes,
  mode: "history"
})

// 解决重复跳转同一个地址报错
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

export default router
